<template>
  <div
    id="my-info-person"
    class="co-flex-col px-6"
  >
    <span class="text-h5 my-4">{{ title }}</span>
    <v-divider />
    <div class="co-w-full co-h40" />
    <el-form
      ref="form"
      :model="frmData"
      :rules="frmRules"
      label-width="100px"
      label-position="right"
    >
      <el-form-item
        label="性别"
        prop="sex"
      >
        <el-radio-group v-model="frmData.sex">
          <el-radio label="男" />
          <el-radio label="女" />
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="生日"
        prop="birthday"
      >
        <el-date-picker
          v-model="frmData.birthday"
          type="date"
          placeholder="选择生日"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item
        label="所在地"
      >
        <div class="co-flex-row co-items-center co-w-full">
          <el-select
            v-model="frmData.province"
            placeholder="请选择"
            style="width: 220px;"
            @change="onProvChange"
          >
            <el-option
              v-for="item in provItems"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="frmData.city"
            placeholder="请选择"
            style="width: 220px; margin-left: 24px;"
          >
            <el-option
              v-for="item in cityItems"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="address"
      >
        <el-input v-model="frmData.address" />
      </el-form-item>
      <el-form-item
        label="兴趣"
        prop="hobby"
      >
        <el-input v-model="frmData.hobby" />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="btnSubmit.loading"
          type="primary"
          style="width: 140px;"
          @click="onSubmit"
        >
          {{ btnSubmit.text }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import provs from '@/api/city-data/province'
  import citys from '@/api/city-data/city'

  export default {
    name: 'InfoPerson',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        title: '个人基本资料',
        frmData: {
          sex: '男',
          birthday: '',
          address: '',
          province: '',
          city: '',
          hobby: ''
        },
        defProv: '32', // 江苏省
        defCity: '3201', // 南京市
        provItems: provs,
        cityItems: [],
        frmRules: {
          sex: []
        },
        btnSubmit: {
          loading: false,
          text: '保存'
        }
      }
    },
    watch: {
      info: {
        handler (val) {
          let addr = api.comm.trim(val.city || '')
          if (addr) {
            addr += '000000'
          } else {
            addr = '320102'
          }

          const prov = addr.substring(0, 2)
          const city = addr.substring(0, 4)
          this.setCityItems(prov)

          this.frmData.sex = val.sex || '男'
          this.frmData.birthday = val.birthday || ''
          this.frmData.address = val.address || ''
          this.frmData.province = prov
          this.frmData.city = city
          this.frmData.hobby = val.hobby || ''
        },
        immediate: true
      }
    },
    created () {
      // this.frmData.prov = this.defProv
    },
    methods: {
      getProvIndex (provCode) {
        let result = -1
        for (const i in provs) {
          const src = provs[i]
          if (src.value === provCode) {
            result = parseInt(i, 10)
            break
          }
        }
        return result
      },
      setCityItems (provCode) {
        const provInd = this.getProvIndex(provCode)
        if (provInd >= 0) {
          this.cityItems = citys[provInd]
          const first = this.cityItems[0]
          this.frmData.city = first.value
        }
      },
      onProvChange (val) {
        console.log({ val })
        this.setCityItems(val)
      },
      getData () {
        const result = {
          sex: this.frmData.sex,
          birthday: this.frmData.birthday,
          province: this.frmData.province,
          city: this.frmData.city,
          address: this.frmData.address,
          hobby: this.frmData.hobby
        }
        return result
      },
      updateInfo (data) {
        const info = Object.assign({}, this.info, data)
        user.info.save(info)
      },
      toConfirm (data) {
        this.$emit('confirm', data)
      },
      onSubmit () {
        const me = this
        const data = this.getData()
        // console.log({ data })
        const executing = function () {
          me.btnSubmit.loading = true
        }
        const executed = function (res) {
          me.btnSubmit.loading = false
          if (res.status) {
            me.updateInfo(data)
            me.toConfirm(data)
            me.$notify({
              title: '成功提示',
              message: '个人基本信息设置成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '个人基本信息设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      },
    }
  }
</script>
